import { useState } from "react";
import { useSelector } from "react-redux";
import AutoCompleteSelect from "../../../../../../components/AutoCompleteSelect/AutoComplete";
import RadioButton from "../../../../../../components/RadioButton/RadioButton";
import TextInput from "../../../../../../components/TextInput/TextInput";
import {
  useFinalSubmitMutation,
  useProposalEnquiry,
} from "../../../../../../services/ProposalServices";
import { onInputPhone, uppercase } from "../../../../../../utils/onInput";
import { yesNoItems } from "../../../../../../utils/YesNoRadioItems";
import { InputWrapper } from "../../../../ProposalForm.style";
import ClosedForm from "../ClosedForm/ClosedForm";
import validation from "./ckycDetailsValidation";
import moment from "moment";
import DatePicker from "../../../../../../components/DatePicker/DatePicker";
import Loader from "../../../../../../components/Loader/Loader";

const CKYCDetails = () => {
  const { proposalData } = useSelector((state) => state.proposal);
  const [errors, setErrors] = useState({});
  const { product } = useProposalEnquiry();
  const [formData, setFormData] = useState({
    ckyc_no: proposalData?.ckyc_no,
    ckyc_dob: proposalData?.ckyc_dob,
    ckyc_pan_no: proposalData?.ckyc_pan_no,
    pan_dob: proposalData?.pan_dob,
    aadhaar_number: proposalData?.aadhaar_number,
    full_name: proposalData?.full_name,
    aadhaar_dob: proposalData?.aadhaar_dob,
    ckyc_gender: proposalData?.ckyc_gender,
    dl_number: proposalData?.dl_number,
    dl_dob: proposalData?.dl_dob,
    file_number: proposalData?.file_number,
    passport_dob: proposalData?.passport_dob,
    photo_doc: proposalData?.photo_doc ? proposalData?.photo_doc : "undefined",
    method_of_verification: "",
    // POI_Type: proposalData?.POI_Type,
    // POI_ID: proposalData?.POI_ID,
    // POI_DocumentFile: proposalData?.POI_DocumentFile,
    // POA_Type: proposalData?.POA_Type,
    // POA_ID: proposalData?.POA_ID,
    // POA_DocumentFile: proposalData?.POA_DocumentFile,
    // Insured_photo: proposalData?.Insured_photo
    //   ? proposalData?.Insured_photo
    //   : "undefined",
    pan_no: proposalData?.pan_no ? proposalData?.pan_no : "",
    address_proof_front_doc: proposalData?.address_proof_front_doc,
    address_proof_back_doc: proposalData?.address_proof_back_doc,
  });

  const { finalSubmit, isLoading } = useFinalSubmitMutation();

  const onChange = async (name, data) => {
    setFormData((prev) => ({ ...prev, [name]: data }));
    validation
      .validateAt(name, {
        ...formData,
        [name]: data,
      })
      .then(() => {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      })
      .catch((error) => {
        setErrors((prev) => ({ ...prev, [name]: error.message }));
      });
  };

  const onSubmit = (onSuccess = () => {}) => {
    validation
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        onSuccess({ ...proposalData, ...formData }, { isFormData: true });
      })
      .catch((error) => {
        console.log("Validation errors:", error);
        const newErrors = {};
        error.inner.forEach((item) => {
          newErrors[item.path] = item.message;
        });

        setErrors(newErrors);
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <ClosedForm
        title={"CKYC Details"}
        index={4}
        next={"Final Submit"}
        onSubmit={onSubmit}
        finalSubmit={finalSubmit}
        isFormData
      >
        <InputWrapper>
          <RadioButton
            items={yesNoItems}
            label="Do you have CKYC no.?"
            onChange={(val) => {
              onChange("is_ckyc_no", val);
            }}
            defaultValue={formData?.is_ckyc_no}
          />
        </InputWrapper>

        {formData?.is_ckyc_no === "Y" && (
          <>
            <InputWrapper>
              <TextInput
                size={"medium"}
                label={"CKYC Number"}
                error={errors?.ckyc_no}
                onInput={onInputPhone}
                maxLength={14}
                onChange={(e) => {
                  onChange("ckyc_no", e.target.value);
                }}
                value={formData?.ckyc_no}
              />
            </InputWrapper>
            {product?.INSURANCE_SLUG === "icici_lombard_general" && (
              <InputWrapper>
                <DatePicker
                  label={"ckyc dob"}
                  size="medium"
                  maxDate={moment().subtract(18, "years")}
                  minDate={moment().subtract(60, "years")}
                  handleChange={(val) => {
                    onChange("ckyc_dob", val);
                  }}
                  readOnly={proposalData?.ckyc}
                  outputFormat="YYYY-MM-DD"
                  value={formData?.ckyc_dob}
                  error={errors?.ckyc_dob}
                />
              </InputWrapper>
            )}
          </>
        )}

        {formData?.is_ckyc_no === "N" &&
          product?.INSURANCE_SLUG === "icici_lombard_general" && (
            <>
              <InputWrapper>
                <AutoCompleteSelect
                  size="medium"
                  label="Method of Verification"
                  error={errors?.method_of_verification}
                  handleChange={(val) => {
                    if (val) {
                      onChange("method_of_verification", val.OPTION_KEY);
                    } else {
                      onChange("method_of_verification", "");
                    }
                  }}
                  _key="OPTION_VAL"
                  options={verification_methods_icici}
                  selectedValue={
                    formData?.method_of_verification
                      ? {
                          OPTION_KEY: formData.method_of_verification,
                          OPTION_VAL:
                            verification_methods_icici.find(
                              (item) =>
                                item.OPTION_KEY ===
                                formData.method_of_verification
                            )?.OPTION_VAL || "",
                        }
                      : null
                  }
                  getOptionLabel={(item) => item.OPTION_VAL || ""}
                  getOptionSelected={(item, val) =>
                    item.OPTION_KEY === val?.OPTION_KEY
                  }
                />
              </InputWrapper>

              {formData.method_of_verification === "pancard" && (
                <>
                  <InputWrapper>
                    <TextInput
                      size="medium"
                      label="Pan Number"
                      error={errors?.ckyc_pan_no}
                      onInput={uppercase}
                      onChange={(e) => {
                        onChange("ckyc_pan_no", e.target.value);
                      }}
                      maxLength={10}
                      value={formData?.ckyc_pan_no}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <DatePicker
                      label="Date of Birth"
                      size="medium"
                      maxDate={moment().subtract(18, "years")}
                      minDate={moment().subtract(60, "years")}
                      handleChange={(val) => {
                        onChange("pan_dob", val);
                      }}
                      readOnly={proposalData?.ckyc}
                      outputFormat="YYYY-MM-DD"
                      value={formData?.pan_dob}
                      error={errors?.pan_dob}
                    />
                  </InputWrapper>
                </>
              )}

              {formData.method_of_verification === "aadharcard" && (
                <>
                  <InputWrapper>
                    <TextInput
                      size="medium"
                      label="Aadhar Number"
                      error={errors?.aadhaar_number}
                      onChange={(e) => {
                        onChange("aadhaar_number", e.target.value);
                      }}
                      maxLength={12}
                      value={formData?.aadhaar_number}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <TextInput
                      size="medium"
                      label="Aadhar Name"
                      error={errors?.full_name}
                      onChange={(e) => {
                        onChange("full_name", e.target.value);
                      }}
                      maxLength={56}
                      value={formData?.full_name}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <DatePicker
                      label="Date of Birth"
                      size="medium"
                      maxDate={moment().subtract(18, "years")}
                      minDate={moment().subtract(60, "years")}
                      handleChange={(val) => {
                        onChange("aadhaar_dob", val);
                      }}
                      readOnly={proposalData?.ckyc}
                      outputFormat="YYYY-MM-DD"
                      value={formData?.aadhaar_dob}
                      error={errors?.aadhaar_dob}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <RadioButton
                      label="Gender"
                      items={genderOptions}
                      defaultValue={formData?.ckyc_gender}
                      onChange={(val) => onChange("ckyc_gender", val)}
                    />
                  </InputWrapper>
                </>
              )}

              {formData.method_of_verification === "drivingdetails" && (
                <>
                  <InputWrapper>
                    <TextInput
                      size="medium"
                      label="Driving License Number"
                      error={errors?.dl_number}
                      onChange={(e) => {
                        onChange("dl_number", e.target.value);
                      }}
                      maxLength={16}
                      value={formData?.dl_number}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <DatePicker
                      label="Driving License Date of Birth"
                      size="medium"
                      maxDate={moment().subtract(18, "years")}
                      minDate={moment().subtract(60, "years")}
                      handleChange={(val) => {
                        onChange("dl_dob", val);
                      }}
                      readOnly={proposalData?.ckyc}
                      outputFormat="YYYY-MM-DD"
                      value={formData?.dl_dob}
                      error={errors?.dl_dob}
                    />
                  </InputWrapper>
                </>
              )}

              {formData.method_of_verification === "passportdetails" && (
                <>
                  <InputWrapper>
                    <TextInput
                      size="medium"
                      label="Passport Number"
                      error={errors?.file_number}
                      onChange={(e) => {
                        onChange("file_number", e.target.value);
                      }}
                      maxLength={12}
                      value={formData?.file_number}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <DatePicker
                      label="Passport Date of Birth"
                      size="medium"
                      maxDate={moment().subtract(18, "years")}
                      minDate={moment().subtract(60, "years")}
                      handleChange={(val) => {
                        onChange("passport_dob", val);
                      }}
                      readOnly={proposalData?.ckyc}
                      outputFormat="YYYY-MM-DD"
                      value={formData?.passport_dob}
                      error={errors?.passport_dob}
                    />
                  </InputWrapper>
                </>
              )}
              {formData.method_of_verification === "icicidocument" && (
                <>
                  <InputWrapper>
                    <AutoCompleteSelect
                      size={"medium"}
                      label={"Address Proof"}
                      _key={"OPTION_VAL"}
                      options={icicipoadocuments}
                      error={errors?.address_proof}
                      handleChange={(val) => {
                        onChange("address_proof", val.OPTION_KEY);
                      }}
                      value={formData?.address_proof}
                      selectedValue={
                        formData?.address_proof && {
                          OPTION_KEY: formData?.address_proof,
                          OPTION_VAL:
                            formData?.address_proof &&
                            icicipoadocuments?.find(
                              (item) =>
                                item.OPTION_KEY === formData?.address_proof
                            )?.OPTION_VAL,
                        }
                      }
                      getOptionLabel={(item) => item.OPTION_VAL || ""}
                      getOptionSelected={(item, val) =>
                        item.OPTION_KEY === val.OPTION_KEY
                      }
                      // Ensure autoComplete is passed as a string value, e.g., "on" or "off"
                      autoComplete="off" // <-- Modify this line as needed
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <TextInput
                      size={"medium"}
                      type="file"
                      label={"Address Proof both Front & backside Document"}
                      error={errors?.address_proof_frontback_doc}
                      onChange={(e) => {
                        onChange(
                          "address_proof_frontback_doc",
                          e.target.files[0]
                        );
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <AutoCompleteSelect
                      size={"medium"}
                      label={"Identity Proof"}
                      _key={"OPTION_VAL"}
                      options={icicipoidocuments}
                      error={errors?.identity_proof}
                      handleChange={(val) => {
                        onChange("identity_proof", val.OPTION_KEY);
                      }}
                      value={formData?.identity_proof}
                      selectedValue={
                        formData?.identity_proof && {
                          OPTION_KEY: formData?.identity_proof,
                          OPTION_VAL:
                            formData?.identity_proof &&
                            icicipoidocuments?.find(
                              (item) =>
                                item.OPTION_KEY === formData?.identity_proof
                            )?.OPTION_VAL,
                        }
                      }
                      getOptionLabel={(item) => item.OPTION_VAL || ""}
                      getOptionSelected={(item, val) =>
                        item.OPTION_KEY === val.OPTION_KEY
                      }
                      // Ensure autoComplete is passed as a string value, e.g., "on" or "off"
                      autoComplete="off" // <-- Modify this line as needed
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <TextInput
                      size={"medium"}
                      type="file"
                      label={"Identity Proof Front & backside Document"}
                      error={errors?.identity_proof_frontback_doc}
                      onChange={(e) => {
                        onChange(
                          "identity_proof_frontback_doc",
                          e.target.files[0]
                        );
                      }}
                    />
                  </InputWrapper>
                </>
              )}
            </>
          )}
        {formData?.is_ckyc_no === "N" &&
          product?.INSURANCE_SLUG === "shri_ram" && (
            <>
              {/* Proof of Identity */}
              <InputWrapper>
                <AutoCompleteSelect
                  label="Proof of Identity"
                  options={POI} // Array of options like [{ OPTION_KEY: 'id1', OPTION_VAL: 'Address 1' }]
                  size="medium"
                  _key="OPTION_VAL"
                  error={errors?.POI_Type}
                  handleChange={(value) => {
                    if (value) {
                      onChange("POI_Type", value.OPTION_KEY); // Update form data with the selected value
                    }
                  }}
                  selectedValue={
                    POI.find(
                      (item) => item.OPTION_KEY === formData?.POI_Type
                    ) || null
                  }
                  getOptionLabel={(option) => option.OPTION_VAL || ""}
                  getOptionSelected={(option, value) =>
                    option.OPTION_KEY === value.OPTION_KEY
                  }
                />
              </InputWrapper>

              {/* Identity Proof Number */}
              <InputWrapper>
                <TextInput
                  size="medium"
                  label="Identity Proof Number"
                  error={errors?.POI_ID}
                  onInput={uppercase} // Assuming uppercase function makes text uppercase
                  onChange={(e) => {
                    onChange("POI_ID", e.target.value);
                  }}
                  maxLength={10}
                  value={formData?.POI_ID}
                />
              </InputWrapper>

              {/* Proof of Identity Document */}
              <InputWrapper>
                <TextInput
                  size="medium"
                  type="file"
                  label="Proof of Identity Doc"
                  error={errors?.POI_DocumentFile}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      // Validate file type (e.g., PDF, JPG, PNG)
                      const isValidFileType = [
                        "application/pdf",
                        "image/jpeg",
                        "image/png",
                      ].includes(file.type);
                      if (!isValidFileType) {
                        errors.POI_DocumentFile =
                          "Invalid file type. Please upload a PDF or image file.";
                        onChange("POI_DocumentFile", null); // Clear file if invalid
                        return;
                      }
                      // Check file size (e.g., 2MB limit)
                      if (file.size > 2 * 1024 * 1024) {
                        // 2MB size limit
                        errors.POI_DocumentFile = "File size exceeds 2MB.";
                        onChange("POI_DocumentFile", null); // Clear file if too large
                        return;
                      }
                      onChange("POI_DocumentFile", file); // Store the valid file
                    }
                  }}
                />
              </InputWrapper>

              {/* Proof of Address */}
              <InputWrapper>
                <AutoCompleteSelect
                  size="medium"
                  label="Proof of Address"
                  _key="OPTION_VAL"
                  options={POA}
                  error={errors?.POA_Type}
                  handleChange={(val) => {
                    if (val) {
                      onChange("POA_Type", val.OPTION_KEY);
                    }
                  }}
                  selectedValue={
                    formData?.POA_Type && {
                      OPTION_KEY: formData?.POA_Type,
                      OPTION_VAL: POA?.find(
                        (item) => item.OPTION_KEY === formData?.POA_Type
                      )?.OPTION_VAL,
                    }
                  }
                  getOptionLabel={(item) => item.OPTION_VAL || ""}
                  getOptionSelected={(item, val) =>
                    item.OPTION_KEY === val.OPTION_KEY
                  }
                />
              </InputWrapper>

              {/* Proof of Address Number */}
              <InputWrapper>
                <TextInput
                  size="medium"
                  label="Proof of Address Number"
                  error={errors?.POA_ID}
                  onInput={uppercase} // Assuming uppercase function makes text uppercase
                  onChange={(e) => {
                    onChange("POA_ID", e.target.value);
                  }}
                  maxLength={10}
                  value={formData?.POA_ID}
                />
              </InputWrapper>

              {/* Proof of Address Document */}
              <InputWrapper>
                <TextInput
                  size="medium"
                  type="file"
                  label="Proof of Address Doc"
                  error={errors?.POA_DocumentFile}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      // Validate file type (PDF, JPG, PNG)
                      const isValidFileType = [
                        "application/pdf",
                        "image/jpeg",
                        "image/png",
                      ].includes(file.type);
                      if (!isValidFileType) {
                        errors.POA_DocumentFile =
                          "Invalid file type. Please upload a PDF or image file.";
                        onChange("POA_DocumentFile", null); // Clear file if invalid
                        return;
                      }
                      // Check file size (e.g., 2MB limit)
                      if (file.size > 2 * 1024 * 1024) {
                        // 2MB size limit
                        errors.POA_DocumentFile = "File size exceeds 2MB.";
                        onChange("POA_DocumentFile", null); // Clear file if too large
                        return;
                      }
                      onChange("POA_DocumentFile", file); // Store the valid file
                    }
                  }}
                />
              </InputWrapper>

              {/* Photo */}
              <InputWrapper>
                <TextInput
                  size={"medium"}
                  type="file"
                  label={"Photo"}
                  name="Insured_photo"
                  error={errors?.Insured_photo}
                  onChange={(e) => {
                    onChange("Insured_photo", e.target.files[0]);
                  }}
                />
              </InputWrapper>
            </>
          )}

        {formData?.is_ckyc_no === "N" &&
          product?.INSURANCE_SLUG !== "icici_lombard_general" &&
          product?.INSURANCE_SLUG !== "shri_ram" && (
            <>
              <InputWrapper>
                <AutoCompleteSelect
                  size="medium"
                  label="Method of Verification"
                  error={errors?.method_of_verification}
                  handleChange={(val) => {
                    // Check if a valid value is selected
                    if (val) {
                      onChange("method_of_verification", val.OPTION_KEY);
                    } else {
                      // Clear the field if no value is selected
                      onChange("method_of_verification", "");
                    }
                  }}
                  _key="OPTION_VAL"
                  options={verification_methods}
                  selectedValue={
                    formData?.method_of_verification
                      ? {
                          OPTION_KEY: formData.method_of_verification,
                          OPTION_VAL:
                            verification_methods.find(
                              (item) =>
                                item.OPTION_KEY ===
                                formData.method_of_verification
                            )?.OPTION_VAL || "", // Fallback to empty string if not found
                        }
                      : null // Set to null when no method_of_verification is present
                  }
                  getOptionLabel={(item) => item.OPTION_VAL || ""}
                  getOptionSelected={
                    (item, val) => item.OPTION_KEY === val?.OPTION_KEY // Ensure val is defined
                  }
                />
              </InputWrapper>
              {formData.method_of_verification === "pan" && (
                <>
                  <InputWrapper>
                    <TextInput
                      size={"medium"}
                      label={"Pan Number "}
                      error={errors?.pan_no}
                      onInput={uppercase}
                      onChange={(e) => {
                        onChange("pan_no", e.target.value);
                      }}
                      maxLength={10}
                      value={formData?.pan_no}
                    />
                  </InputWrapper>
                </>
              )}
              {formData.method_of_verification === "doc" && (
                <>
                  <InputWrapper>
                    <AutoCompleteSelect
                      size={"medium"}
                      label={"Address Proof"}
                      _key={"OPTION_VAL"}
                      options={documents}
                      error={errors?.address_proof}
                      handleChange={(val) => {
                        onChange("address_proof", val.OPTION_KEY);
                      }}
                      value={formData?.address_proof}
                      selectedValue={
                        formData?.address_proof && {
                          OPTION_KEY: formData?.address_proof,
                          OPTION_VAL:
                            formData?.address_proof &&
                            documents?.find(
                              (item) =>
                                item.OPTION_KEY === formData?.address_proof
                            )?.OPTION_VAL,
                        }
                      }
                      getOptionLabel={(item) => item.OPTION_VAL || ""}
                      getOptionSelected={(item, val) =>
                        item.OPTION_KEY === val.OPTION_KEY
                      }
                      // Ensure autoComplete is passed as a string value, e.g., "on" or "off"
                      autoComplete="off" // <-- Modify this line as needed
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <TextInput
                      size={"medium"}
                      type="file"
                      label={"Address Proof Frontside Document "}
                      error={errors?.address_proof_front_doc}
                      onChange={(e) => {
                        onChange("address_proof_front_doc", e.target.files[0]);
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <TextInput
                      size={"medium"}
                      type="file"
                      label={"Address Proof Backside Document "}
                      error={errors?.address_proof_back_doc}
                      onChange={(e) => {
                        onChange("address_proof_back_doc", e.target.files[0]);
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <AutoCompleteSelect
                      size={"medium"}
                      label={"Identity Proof"}
                      _key={"OPTION_VAL"}
                      options={documents}
                      error={errors?.identity_proof}
                      handleChange={(val) => {
                        onChange("identity_proof", val.OPTION_KEY);
                      }}
                      value={formData?.identity_proof}
                      selectedValue={
                        formData?.identity_proof && {
                          OPTION_KEY: formData?.identity_proof,
                          OPTION_VAL:
                            formData?.identity_proof &&
                            documents?.find(
                              (item) =>
                                item.OPTION_KEY === formData?.identity_proof
                            )?.OPTION_VAL,
                        }
                      }
                      getOptionLabel={(item) => item.OPTION_VAL || ""}
                      getOptionSelected={(item, val) =>
                        item.OPTION_KEY === val.OPTION_KEY
                      }
                      // Ensure autoComplete is passed as a string value, e.g., "on" or "off"
                      autoComplete="off" // <-- Modify this line as needed
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <TextInput
                      size={"medium"}
                      type="file"
                      label={"Identity Proof Frontside Document "}
                      error={errors?.identity_proof_front_doc}
                      onChange={(e) => {
                        onChange("identity_proof_front_doc", e.target.files[0]);
                      }}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <TextInput
                      size={"medium"}
                      type="file"
                      label={"Identity Proof Backside Document "}
                      error={errors?.identity_proof_back_doc}
                      onChange={(e) => {
                        onChange("identity_proof_back_doc", e.target.files[0]);
                      }}
                    />
                  </InputWrapper>
                </>
              )}
              <InputWrapper>
                <TextInput
                  size={"medium"}
                  type="file"
                  label={"Photo "}
                  error={errors?.photo_doc}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    onChange("photo_doc", file); // Pass the file object
                  }}
                />
              </InputWrapper>
            </>
          )}
      </ClosedForm>
    </>
  );
};

export default CKYCDetails;

const verification_methods = [
  {
    OPTION_KEY: "pan",
    OPTION_VAL: "PAN Number",
  },
  {
    OPTION_KEY: "doc",
    OPTION_VAL: "Physical Documents",
  },
];

const verification_methods_icici = [
  {
    OPTION_KEY: "pancard",
    OPTION_VAL: "Pancard Details",
  },
  {
    OPTION_KEY: "aadharcard",
    OPTION_VAL: "Aadhar Details",
  },
  {
    OPTION_KEY: "drivingdetails",
    OPTION_VAL: "Driving License",
  },
  {
    OPTION_KEY: "passportdetails",
    OPTION_VAL: "Passport Details",
  },
  {
    OPTION_KEY: "icicidocument",
    OPTION_VAL: "Physical Documents",
  },
];
const documents = [
  {
    OPTION_KEY: "D20",
    OPTION_VAL: "CGHS ECHS CARD",
  },
  {
    OPTION_KEY: "D21",
    OPTION_VAL: "PHOTO CREDIT CARD",
  },
  {
    OPTION_KEY: "D22",
    OPTION_VAL: "IT PROOF",
  },
  {
    OPTION_KEY: "D23",
    OPTION_VAL: "PHOTO PENSIONER CARD",
  },
  {
    OPTION_KEY: "D24",
    OPTION_VAL: "BANK STATEMENT",
  },
  {
    OPTION_KEY: "D25",
    OPTION_VAL: "AADHAAR IMAGE",
  },
  {
    OPTION_KEY: "D26",
    OPTION_VAL: "DRIVING LICENSE IMAGE",
  },
  {
    OPTION_KEY: "D27",
    OPTION_VAL: "VOTER ID IMAGE",
  },
  {
    OPTION_KEY: "D28",
    OPTION_VAL: "PASSPORT IMAGE",
  },
];

const icicipoidocuments = [
  {
    OPTION_KEY: "PAN",
    OPTION_VAL: "PANCARD",
  },
  {
    OPTION_KEY: "AADHAAR",
    OPTION_VAL: "AADHAR",
  },
  {
    OPTION_KEY: "VOTERID",
    OPTION_VAL: "VOTERID",
  },
  {
    OPTION_KEY: "DL",
    OPTION_VAL: "DRIVINGLICENSE",
  },
  {
    OPTION_KEY: "PASSPORT",
    OPTION_VAL: "PASSPORT",
  },
];

const icicipoadocuments = [
  {
    OPTION_KEY: "PAN",
    OPTION_VAL: "PANCARD",
  },
  {
    OPTION_KEY: "AADHAAR",
    OPTION_VAL: "AADHAR",
  },
  {
    OPTION_KEY: "VOTERID",
    OPTION_VAL: "VOTERID",
  },
  {
    OPTION_KEY: "DL",
    OPTION_VAL: "DRIVINGLICENSE",
  },
];

const genderOptions = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
];

const POI = [
  {
    OPTION_KEY: "POI_Pancard",
    OPTION_VAL: "PANCARD",
  },
  {
    OPTION_KEY: "POI_Aadhar",
    OPTION_VAL: "PROOF OF POSSESSION OF AADHAR",
  },
  {
    OPTION_KEY: "POI_Passport",
    OPTION_VAL: "Passport",
  },
  {
    OPTION_KEY: "POI_Voter",
    OPTION_VAL: "Voter ID",
  },
  {
    OPTION_KEY: "POI_CI",
    OPTION_VAL: "Certificate of Incorporation",
  },
  {
    OPTION_KEY: "POI_RC",
    OPTION_VAL: "Registration Certificate",
  },
];

const POA = [
  {
    OPTION_KEY: "POA_Aadhar",
    OPTION_VAL: "PROOF OF POSSESSION OF AADHAR ",
  },
  {
    OPTION_KEY: "POA_Passport",
    OPTION_VAL: "Passport",
  },
  {
    OPTION_KEY: "POA_Voter",
    OPTION_VAL: "Voter ID",
  },
  {
    OPTION_KEY: "POA_CI",
    OPTION_VAL: "Certificate of Incorporation",
  },
  {
    OPTION_KEY: "POA_RC",
    OPTION_VAL: "Registration Certificate",
  },
];
